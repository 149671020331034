<template>
  <div class="container-fluid">
  <Header />
  <div class="container mt-5 content">
    <div class="modal fade" id="facultyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Form</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">อีเมล</label>
                <input type="text" class="form-control" v-model="email">
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">คณะ</label>
                 <select v-model="faculty" class="form-control">
                  <option v-for="item in facultyList" :key="item._id" :value="item.facultyname"> {{ item.facultyname }} </option>
                </select>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="onSave()" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ufacultyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Form</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">อีเมล</label>
                <input type="text" class="form-control" placeholder="input faculty" v-model="u_email.email">
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">คณะ</label>
                <select v-model="u_email.faculty" class="form-control">
                  <option v-for="item in facultyList" :key="item._id" :value="item.facultyname"> {{ item.facultyname }} </option>
                </select>
                <!-- <input type="text" class="form-control" placeholder="input faculty" v-model="u_email.faculty"> -->
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">ปิด</button>
        <button type="button" class="btn btn-primary" @click="onSave()" data-bs-dismiss="modal">บันทึก</button>
      </div>
    </div>
  </div>
</div>
      
      <!-- <input type="file" @change="onChange($event)"> -->
      <button class="btn btn-outline-info btn-add" @click="onAdd()" data-bs-toggle="modal" data-bs-target="#facultyModal" type="button">เพิ่ม</button>

    <table class="table table-striped" id="userTable">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">อีเมล</th>
      <th scope="col">คณะ</th>
      <th scope="col" class="text-center">จัดการข้อมูล</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in userList" :key="index">
      <th scope="row">{{ index + 1 }}</th>
      <td> {{ item.email }} </td>
      <td> {{ item.faculty }} </td>
      <td class="text-center">
        <button type="button" @click="onUpdate(item)" data-bs-toggle="modal" data-bs-target="#ufacultyModal" class="btn btn-outline-info"><i class="fas fa-pencil-alt"></i></button>
        <button type="button" @click="onDelete(item._id)" class="btn btn-outline-danger"><i class="far fa-trash-alt"></i></button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
  <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from '@/components/Header.vue';
import * as XLSX from 'xlsx';

export default {
  name: 'Home',
  components: {
    Header
  },
  data() {
    return {
      userList: '',
      email: '',
      faculty: '',
      length: '',
      facultyList: '',
      u_email: {
          email: '',
          faculty: ''
      },
      addOrUpdate: ''
    }
  },
  mounted() {
      this.onInit();

      axios.get('/api/faculty').then(res => {
        this.facultyList = res.data;
      })
  },
  methods: {
    onSave(){
      if (this.addOrUpdate == 'add') {
        if (this.email != '' && this.faculty != '') {
          axios.post('/api/user/user/create', {
            email: this.email,
            faculty: this.faculty
          }).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.email = '';
            this.faculty = '';
            $('#userTable').DataTable().destroy();
            this.onInit();
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'please input data!',
          })
        }
      }else{
        if (this.u_email.email != '' && this.u_email.faculty) {
          axios.put(`/api/user/${this.u_email._id}`, this.u_email).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.u_email.email = '';
            this.u_email.faculty = '';
            this.onInit();
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'please input data!',
          })
        }
      }
    },
    onInit(){
      axios.get('/api/user').then(res => {
          this.userList = res.data;
          this.length = res.data.length - 1;
          $(document).ready(function() {
            $('#userTable').DataTable({
                'pageLength': 25,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                "bDestroy": true,
                columnDefs: [
                    { orderable: false, targets: -1 }
                ],
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
      })
    },
    onDelete(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/user/user/create/${id}`).then(res => {
              Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            $('#userTable').DataTable().destroy();
            this.onInit();
          })
        }
      })
    },
    onUpdate(item){
      this.addOrUpdate = 'update';
      this.u_email = item;
    },
    onAdd(){
      this.addOrUpdate = 'add';
    },
    onChange(event) {
      const data  = '';
      this.file = event.target.files ? event.target.files[0] : null;
      this.fileupload = event.target.files[0].name;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // data = XLSX.utils.sheet_to_json(ws, { raw:true });
          
          axios.post('/api/faculty', XLSX.utils.sheet_to_json(ws, { raw:true }), {
            onUploadProgress: progressEvent => {
              // this.progress = 'width: ' + Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%';
            }
          }).then(res => {
            
          })
        }

        reader.readAsBinaryString(this.file);
      }
    }
  },
}
</script>

<style scoped>
.container-fluid{
    padding: 0;
}
 .btn-add{
     float: right;
 }
 .content{
    margin-bottom: 6%;
}
</style>
