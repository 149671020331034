<template>
  <div class="container-fluid">
    <div class="login-dark">
      <div>
      </div>
      <div class="title text-center">
        <!-- <p style="font-size: 2vw; font-weight:400; text-shadow: 2px 2px #333;">"การแถลงข่าว มหาวิทยาลัยเชียงใหม่ ประจำปี 2564"</p> -->
      </div>
         <form>
            <div class="logo">
              <img class="logo" src="@/assets/logo_rmutl.png" alt="logo">
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-success btn-block mt-4" @click="onShowModal()">LOGIN</button>
              <button type="button" class="btn btn-primary btn-block mt-4" @click="onLogin()">CMU ACCOUNT</button>
            </div>
          </form>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Login</h5>
            <button type="button" class="btn" data-dismiss="modal" aria-label="Close" @click="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Username</label>
                <input type="text" class="form-control" placeholder="Enter Username" v-model="form.username" >
              </div>
              <div class="form-group mt-2">
                <label for="exampleInputPassword1">Password</label>
                <input type="password" class="form-control" placeholder="Enter Password" v-model="form.password" >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal()" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="onLannaLogin()">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../router';

export default {
  name: 'Home',
  components: {
    
  },
  mounted() {
      if (this.$route.query.code) {
        axios.get(`/api/user/${this.$route.query.code}`).then(response => {
            if (response.data._id) {
              localStorage.setItem('session', response.data._id);
              this.$router.push({ name: "livestream" });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Access Denied!',
              })
            }
        })
      }

      axios.post('/api/user', { _id: localStorage.getItem('session') })
        .then(res => {
            if (res.data.status) {
                this.$router.push({ name: 'livestream' });
            }
        })

  },
  methods: {
    onLogin(){
      window.location.href = 'https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=Ps8T7afHBGVSksnBeNjJ17s7y2kMp26EpPQd0j7j&redirect_uri=https://graduate.virtual-event.cmu.ac.th&scope=cmuitaccount.basicinfo'
      // window.location.href = 'https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=Ps8T7afHBGVSksnBeNjJ17s7y2kMp26EpPQd0j7j&redirect_uri=http://localhost:8080&scope=cmuitaccount.basicinfo'
    },
    onShowModal(){
      $('#myModal').modal('show');
    },
    closeModal(){
      $('#myModal').modal('hide');
    },
    onLannaLogin(){
      axios.post('/api/lanna/login', this.form)
        .then(res => {
          if (res.data !== 'nok') {
            localStorage.setItem('session', res.data._id);
            this.$router.push({ name: "livestream", params: { type: 'lanna' } });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Username or Password in valid!',
              showConfirmButton: false,
              timer: 2500
            })
          }

          this.closeModal();
        })
        .catch(err => {

        })
    },
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
}
</script>

<style scoped>

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}
.container-fluid{
  padding: 0;
}

.logo{
  width: 100%;
  height: auto;
  margin-bottom: 7%;
}

.login-dark {
  height:100vh;
  width: 100%;
  background: url('../assets/bg_rmutl.png');
  background-position: center;
  background-repeat: no-repeat;
  position:relative;
}

.login-dark .title {
  transform:translate(-50%, -50%);
  position:absolute;
  top:25%;
  left:50%;
}

.login-dark .title2 {
  transform:translate(-50%, -50%);
  position:absolute;
  bottom: 5%;
  left:50%;
}

p{
  color: #fff;
}

.login-dark form {
  max-width:320px;
  width:90%;
  background-color:#333;
  padding:40px;
  border-radius:4px;
  transform:translate(-50%, -50%);
  position:absolute;
  top:55%;
  left:50%;
  color:#333;
  box-shadow:3px 3px 4px rgba(0,0,0,0.2);
}

.login-dark .illustration {
  text-align:center;
  padding:15px 0 20px;
  font-size:100px;
  color:#2980ef;
}

.login-dark form .form-control {
  background:none;
  border:none;
  border-bottom:1px solid #434a52;
  border-radius:0;
  box-shadow:none;
  outline:none;
  color:inherit;
}

.login-dark form .btn-primary {
  background:#214a80;
  border:none;
  border-radius:4px;
  padding:11px;
  box-shadow:none;
  margin-top:26px;
  text-shadow:none;
  outline:none;
}

.login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
  background:#214a80;
  outline:none;
}

.login-dark form .forgot {
  display:block;
  text-align:center;
  font-size:12px;
  color:#6f7a85;
  opacity:0.9;
  text-decoration:none;
}

.login-dark form .forgot:hover, .login-dark form .forgot:active {
  opacity:1;
  text-decoration:none;
}

.login-dark form .btn-primary:active {
  transform:translateY(1px);
}
</style>
