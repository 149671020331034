<template>
  <header>
      <div class="px-3 py-2 text-black">
        <div class="container mt-2">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="/live" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                <img src="@/assets/logo_rmutl.png" alt="logo">
            </a>

            <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                <li v-if="role == 'admin'" class="nav-item">
                  <a class="nav-link" href="/manage">จัดการสิทธิ์</a>
                </li>
                <li v-if="role == 'adminlanna'" class="nav-item">
                  <a class="nav-link" href="/managelanna">จัดการสิทธิ์</a>
                </li>
                <li>
                <button @click="onLogout()" class="btn btn-outline-dark">
                    ออกจากระบบ
                </button>
                </li>
            </ul>
            </div>
        </div>
        </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    
  },
  mounted() {
      axios.post('/api/user', { _id: localStorage.getItem('session') })
        .then(res => {
            if (res.data.status) {
                this.role = res.data.data.role;
            }
        })

      axios.post('/api/lanna/session', { _id: localStorage.getItem('session') })
        .then(res => {
            if (res.data.status) {
                this.role = res.data.data.role;
            }
        })

  },
  methods: {
    onLogout(){
      axios.delete(`/api/user/${localStorage.getItem('session')}`).then(res => {
        this.$router.push({ name: "Home" });
      })
    }
  },
  data() {
    return {
      role: ''
    }
  },
}
</script>

<style scoped>
header{
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(233, 182, 134) 0%, rgb(221, 139, 44) 100%) !important;
}
ul li a{
  color: #333;
}
ul li a:hover{
  color: #fff;
}
</style>
