import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import LiveStream from '../views/LiveStream.vue';
import Manage from '../views/ManageUser.vue';
import ManageLanna from '../views/ManageLanna.vue';
import Axios from 'axios';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/live',
        name: 'livestream',
        component: LiveStream,
        meta: { auth: true }
    },
    {
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: { auth: ['admin'] }
    },
    {
        path: '/managelanna',
        name: 'managelanna',
        component: ManageLanna,
        meta: { auth: ['adminlanna'] }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const auth = to.meta.auth;
    if (!auth) return next();
    Axios.post('/api/user', { _id: localStorage.getItem('session') })
        .then(res => {
            if (res.data.status == false) {
                next({ name: 'Home' });
            } else {
                if (!Array.isArray(auth)) return next();
                const userLogin = res.data.data.role;
                if (auth.indexOf(userLogin) >= 0) return next();
            }
        })
        .catch(err => {
            next({ name: 'Home' });
        })
})

export default router