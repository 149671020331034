import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App)
    .use(store)
    .use(router)
    // .use(VuePlyr, {
    //     plyr: {}
    // })
    .mount('#app')