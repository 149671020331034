<template>
  <div class="container-fluid">
    <Header/>
    <div class="container con-video mt-2">
          <iframe class="responsive-iframe" src="https://virtual-event.cmu.ac.th/live/" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';

export default {
  name: 'Home',
  components: {
    Header
  },
  computed: {
    
  },
  mounted() {
      // axios.get('/api/live').then(res => {
      //   this.source = res.data.link;
      // })

  },
  methods: {
    viewCore(id, player) {
      this.players[id] = player;
    },
  },
  data() {
    return {
      source: 'https://wowza.mooc.cmu.ac.th/LiveITSC/cmu/playlist.m3u8',
      
    }
  },
}
</script>

<style scoped>
.container-fluid{
  padding: 0;
  background-image: url('../assets/bg01.jpeg') !important ;
  background-repeat: no-repeat;
  height: 100vh;
}

iframe{
  width: 1200px;
  height: 675px;
}

.container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
